import React from "react";
import { graphql } from "gatsby";
import Seo from "../../components/Seo";
import Layout from "../../layouts/index";
import n4 from "../../images/news4.jpg";
import NFooter from "../../components/nFooter";

const News = (props) => {
  return (
    <Layout bodyClass="page-news noteworthy">
      <Seo
        title="Former Navantis CEO Joins iTelaSoft"
        description="One of Sri Lanka’s cutting-edge tech companies, iTelaSoft, recently announced its second consecutive year of rapid growth following the entry of former Navantis Chief Executive Officer, Indaka Raigama, as its CEO."
      />

      <div className="intro intro-banner" id="up">
        <div className="intro-banner-img news4-bnr">
          <img alt="Former Navantis CEO Joins" src={n4} />
        </div>
        <div className="container container-narrow intro-title noteworthy-title">
          <div className="row">
            <div className="col-12">
              <div className="date">NEWS | FEBRUARY 12, 2018</div>
              <h1>Former Navantis CEO Joins iTelaSoft</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container pt-4 pt-md-5 mb-6">
        <div className="row justify-content-start">
          <div className="col-12 col-md-12">
            <div className="content-body">
              <div className="content">
                <p>
                  One of Sri Lanka’s cutting-edge tech companies, iTelaSoft,
                  recently announced its second consecutive year of rapid growth
                  following the entry of former Navantis Chief Executive
                  Officer, Indaka Raigama, as its CEO.
                </p>
                <p></p>
                <p>
                  Raigama has a unique track record of setting up teams and
                  penetrating to newer markets and technology frontiers in his
                  role. Graduating from the Engineering Faculty of the
                  University of Moratuwa in 1998, he has worked on software
                  engineering, IT consulting, managing projects, and
                  successfully running enterprises for the last two decades. His
                  wealth of prior experience at Virtusa and Navantis has shaped
                  his personality to successfully face the challenges of growing
                  a tech company. As a former director at SLASSCOM, he has
                  contributed in no mean measure to grow the IT industry by
                  popularising IT careers among the young generation and
                  penetrating to remote areas in Sri Lanka. Indaka joined
                  iTelaSoft last year with the intention to grow the operation
                  in the right direction.
                </p>
                <p>
                  iTelaSoft co-founder Ravindra Samarasinghe said: “When Indaka
                  joined the organisation in early 2016, it had less than twelve
                  employees, but he demonstrated the company’s ability to
                  provide complex solutions to many clients around the world.
                  His dedication, with tremendous support from his team, means
                  the organisation is today a team of more than 60 engineering
                  professionals, serving successfully numerous customers in
                  Australia, UK, USA, and Canada.”
                </p>
                <p>
                  “Another growing business opportunity is providing highly
                  skilled talent to these markets who are continuously looking
                  to improve productivity. These dedicated teams are growing to
                  cater for the ever-growing demand of product evolution,
                  professional services, and process automation. With Indaka’s
                  guidance and strategic thinking, we were able to reach global
                  markets and diversify our offerings,” Samarasinghe added.
                </p>
                <p>
                  Addressing a gala event iTelaSoft held recently to celebrate
                  the success of 2017 and cascading the plan for 2018 along with
                  its employees and partners, Raigama said: “iTelaSoft had its
                  ambitious beginning seven years back, to become a cutting-edge
                  tech company with world-wide presence. Two years back, when I
                  joined iTelaSoft, I wanted to fully focus on that mission.
                  During the past years we have built very innovative solutions
                  using cutting-edge technology stacks, delivering significant
                  value to our customers. My passion has been to build teams,
                  organisations, and products that I can be proud of. I strongly
                  believe that the culture and values of an organisation have a
                  significant impact on its achievements. As a team, we will be
                  focusing on our talent base, competencies, and the culture to
                  become a world-class technology provider.”
                </p>
                <p>
                  iTelaSoft is an IT services provider and software products
                  development company, specialising in IT strategy consultation,
                  design, development, and management of products, along with
                  helping entrepreneurs transform ground-breaking ideas into
                  successful products. The company was started with just one
                  employee in 2010,targeting business from Australia. The
                  co-founders set the goal to take the organisation global with
                  world-class standards using local talent.
                </p>
                <p>
                  iTelaSoft works with many business domains including telco,
                  fintech, eLearning, and retail. Already, the organisation has
                  supported a few disruptive ideas to become real-world
                  products.One of the niche areas of iTelaSoft is the Internet
                  of Things (IoT), and, in most markets, IoT is growing at a
                  rapid pace.
                </p>
                <p>
                  Addressing the gala event, Open University of Sri Lanka Senior
                  Lecturer and Information and Communication Technology Agency
                  of Sri Lanka (ICTA) Director, Dr. Ajith Madurapperuma,
                  expressed his pleasure to see young technology companies in
                  Sri Lanka building cutting-edge solutions for top brands in
                  the world. He stressed the fact that we have to be in a
                  full-time learning mind-set to be relevant in the world
                  market. Being only literate is not enough as a nation; we need
                  to be technology literate and culturally literate to
                  understand how to build products that become applicable to the
                  rest of the world.
                </p>
                <p>
                  Raigama also lauded the country’s intention to become a prime
                  technology hub in South Asia, commenting: “Sri Lanka’s ICT
                  exports are expected to reach $5 billion by 2020. Initiatives
                  such as knowledge-based social market economy, an
                  export-oriented economy, Western Region Megapolis, Smartcities
                  etc. will propel the country’s IT industries as never seen
                  before. Export revenue of the Sri Lankan IT sector skyrocketed
                  from $213 million in 2007 to US$720 million in 2013, and then
                  reached $1 billion in 2016. Sri Lanka was ranked among the
                  ‘Top 50 Outsourcing Destinations’ by AT Kearney, whilst
                  Colombo was ranked among the ‘Top 20 Emerging Cities’ by
                  Global Services Magazine. In addition,50% of the one million
                  jobs projected by 2020 would come from information
                  communication technology (ICT) along with tourism sectors as
                  envisaged by the government.”
                </p>
                <p>
                  “We commend and support Sri Lanka’s ambition to become the IT
                  and technology hub of the region. iTelaSoft is a great
                  breeding ground for young talented graduates to complete their
                  internship and start careers as they are exposed to a variety
                  of subject areas, international businesses, and opportunities
                  to work in real-life projects. So far, we have seen a really
                  bright set of young minds joining our organisation. With such
                  a talent pool and more organisations like iTelaSoft, Sri Lanka
                  to become a niche IT hub in the region is no doubt
                  achievable,” concluded a confident Raigama.
                </p>
              </div>
            </div>
          </div>
        </div>
        <NFooter
          prv1="/noteworthy/itelasoft-night-2018"
          prv2="/noteworthy/itelasoft-night-2018"
          next1="/noteworthy/new-it-platform-initiative-from-itelasoft"
          next2="/noteworthy/new-it-platform-initiative-from-itelasoft"
          titlel="iTelaSoft Night 2018"
          titler="iTelaSoft Leaps into Narrowband IoT"
        />
      </div>
    </Layout>
  );
};

export default News;
